.formandlist {
  display: flex;
  width: auto;
  // justify-content: center;
  flex-direction: row;
}

.pagehead {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 55rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  // margin-left: auto;
  // margin-right: auto;
  margin-bottom: 2rem;
}

.btn-primary {
  background-color: #bf6a39;
  color: white;
  border: solid 1px #bf6a39;
  margin-top: 1rem;
}

.areafind {
  display: none;
}

.layout {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.vendors {
  // border: solid #bf6a39 1px;
  border-radius: 4px;
  width: 40%;
  &__list {
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    padding-top: 1rem;
    padding-right: 1rem;
  }
  &__list-head {
    margin: 1rem;
  }
}
