@import url("https://fonts.googleapis.com/css2?family=Monoton&display=swap");
.header {
  background-color: #bf6a39;
}

.nav-link {
  color: #f2d6bd;
}

.directions {
  color: #bf6a39;
  font-size: 2rem;
}

.link {
  text-decoration: none;
  color: #f2d6bd;
}

.nav-pills {
  color: #f2d6bd;
  // width: 17rem;
  // border: solid black 1px;
  // display: flex;
  // flex-direction: row;
  // justify-content: space-around;
}

.header-link {
  // margin-left: 1rem;
  // margin-right: 1rem;
  margin-top: 0.25rem;
  // &__about {
  //   margin-left: 1rem;
  //   margin-top: 0.5rem;
  // }
}

.nav-item {
  // border: solid black 1px;
  width: 10rem;
  display: flex;
  justify-content: center;
  &__1 {
    width: 10rem;
    display: flex;
    justify-content: center;
  }
}

.link {
  &__logo {
    font-family: "Monoton", cursive;
    margin-right: 1.5rem;
  }
}

@media only screen and (min-width: 540px) {
  .nav-item {
    width: 5rem;
    display: flex;
    justify-content: flex-end;
    &__1 {
      width: 5rem;
      display: flex;
      justify-content: flex-end;
    }
  }
}

@media only screen and (min-width: 768px) {
  .nav-item {
    // border: solid 1px black;
    width: 8rem;
    display: flex;
    justify-content: flex-end;
    &__1 {
      // border: solid 1px black;
      width: 6rem;
    }
  }
}

@media only screen and (min-width: 1280px) {
  .nav-item {
    width: 9rem;
    &__1 {
      width: 6rem;
    }
  }
}
