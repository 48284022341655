@import url(https://fonts.googleapis.com/css2?family=Monoton&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Monoton&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lobster&display=swap);
.App{text-align:center}.App-logo{height:40vmin;pointer-events:none}@media(prefers-reduced-motion: no-preference){.App-logo{-webkit-animation:App-logo-spin infinite 20s linear;animation:App-logo-spin infinite 20s linear}}.App-header{background-color:#282c34;min-height:100vh;display:flex;flex-direction:column;align-items:center;justify-content:center;font-size:calc(10px + 2vmin);color:#fff}.App-link{color:#61dafb}@-webkit-keyframes App-logo-spin{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes App-logo-spin{from{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}
.hero{color:#bf6a39;font-size:1.5rem}.title{font-family:"Monoton",cursive;font-size:2.5rem;color:#bf6a39}.login__cta{display:flex;flex-direction:column}
.header{background-color:#bf6a39}.nav-link{color:#f2d6bd}.directions{color:#bf6a39;font-size:2rem}.link{text-decoration:none;color:#f2d6bd}.nav-pills{color:#f2d6bd}.header-link{margin-top:.25rem}.nav-item{width:10rem;display:flex;justify-content:center}.nav-item__1{width:10rem;display:flex;justify-content:center}.link__logo{font-family:"Monoton",cursive;margin-right:1.5rem}@media only screen and (min-width: 540px){.nav-item{width:5rem;display:flex;justify-content:flex-end}.nav-item__1{width:5rem;display:flex;justify-content:flex-end}}@media only screen and (min-width: 768px){.nav-item{width:8rem;display:flex;justify-content:flex-end}.nav-item__1{width:6rem}}@media only screen and (min-width: 1280px){.nav-item{width:9rem}.nav-item__1{width:6rem}}
.location-search-input{width:100% !important;line-height:1.25;color:#000;display:block;width:100%;font-size:1rem;font-weight:400;color:#070707;background-color:#fff;background-clip:padding-box;border:1px solid #ced4da;-webkit-appearance:none;appearance:none;border-radius:.25rem;transition:border-color .15s ease-in-out,box-shadow .15s ease-in-out}::-webkit-input-placeholder{color:#000}:-ms-input-placeholder{color:#000}::placeholder{color:#000}#floatingInput{padding:1rem}.address{height:4rem;margin-bottom:8rem}
.signup__title{margin:1rem}.signup__summ{margin:1rem}
.bussiness__sign{margin:1rem}.bussiness__summ{margin:1rem}

.container__map{padding-left:20%;padding-right:20%}.map{max-width:140rem}
.list-group{margin-left:1rem;width:20%}.vendors{text-decoration:none;font-size:.8rem;margin-left:0rem}.vendors__box{margin:0rem 1rem 1rem 1rem;border-radius:4px;background-color:#bf6a39;color:#fff}.vendors__list-head{font-size:1rem}.vendor__cat{margin-bottom:0}.vendor__bussname{width:20rem;font-size:.6rem}.vendor__address__no{margin-right:.5rem;margin-bottom:0;font-size:.6rem}
.formandlist{display:flex;width:auto;flex-direction:row}.pagehead{display:flex;flex-direction:column;justify-content:center;width:55rem;margin-left:1.5rem;margin-right:1.5rem;margin-bottom:2rem}.btn-primary{background-color:#bf6a39;color:#fff;border:solid 1px #bf6a39;margin-top:1rem}.areafind{display:none}.layout{display:flex;align-items:center;flex-direction:column}.vendors{border-radius:4px;width:40%}.vendors__list{display:flex;flex-direction:column;padding-left:1rem;padding-top:1rem;padding-right:1rem}.vendors__list-head{margin:1rem}
.vendoreditform{display:flex;justify-content:center;flex-direction:column;align-content:center;margin-left:auto;margin-right:auto}.vendoreditform__form{max-width:20rem;margin-left:auto;margin-right:auto}.vendoreditform__title{margin:1rem}.vendoreditform__summ{margin:1rem}
.landing{display:flex;margin:1rem}.landing__signup{margin:1rem}.landing__update{margin:1rem}.landing__girl-img{width:90%}.page-wrapper{display:flex;flex-direction:column;align-items:center}
.about{max-width:80rem;display:flex;flex-direction:column;font-family:"Lobster",cursive;padding:1rem}.about__title{margin:1.5rem}.about__para{line-height:2.5rem;color:#bf6a39;font-family:"Lobster",cursive;font-size:2rem;margin:1.5rem}.about__img{width:20rem}.about__container{display:flex;flex-direction:column;align-items:center}.image__center{display:flex;justify-content:center}@media only screen and (min-width: 768px){.about__img{width:50rem}}
.title__custupdate{margin-left:.5rem}.title__custinst{margin-left:.5rem}.form{margin:.5rem}
