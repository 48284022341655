.title {
  &__custupdate {
    margin-left: 0.5rem;
  }
  &__custinst {
    margin-left: 0.5rem;
  }
}

.form {
  margin: 0.5rem;
}
