@import url("https://fonts.googleapis.com/css2?family=Monoton&display=swap");

.hero {
  color: #bf6a39;
  font-size: 1.5rem;
}

.title {
  font-family: "Monoton", cursive;
  font-size: 2.5rem;
  color: #bf6a39;
}

.login {
  &__cta {
    display: flex;
    flex-direction: column;
  }
}
