.landing {
  display: flex;
  margin: 1rem;
  &__signup {
    margin: 1rem;
  }

  &__update {
    margin: 1rem;
  }

  &__girl-img {
    width: 90%;
  }
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
