.list-group {
  margin-left: 1rem;
  width: 20%;
  // border: solid 1px black;
}

.vendors {
  text-decoration: none;
  font-size: 0.8rem;
  margin-left: 0rem;
  &__box {
    margin: 0rem 1rem 1rem 1rem;
    // padding-bottom: 0.5rem;
    border-radius: 4px;
    background-color: #bf6a39;
    color: white;
    // height: auto;
    // max-width: 10rem;
  }
  &__list-head {
    font-size: 1rem;
  }
}

.vendor {
  &__cat {
    margin-bottom: 0;
  }
  &__bussname {
    width: 20rem;
    font-size: 0.6rem;
  }
}

.vendor__address {
  &__no {
    margin-right: 0.5rem;
    margin-bottom: 0;
    font-size: 0.6rem;
  }
}
