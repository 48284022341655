@import url("https://fonts.googleapis.com/css2?family=Lobster&display=swap");

.about {
  max-width: 80rem;
  display: flex;
  flex-direction: column;
  font-family: "Lobster", cursive;
  padding: 1rem;

  &__title {
    margin: 1.5rem;
  }

  &__para {
    line-height: 2.5rem;
    color: #bf6a39;
    font-family: "Lobster", cursive;
    font-size: 2rem;
    margin: 1.5rem;
  }
  &__img {
    width: 20rem;
  }
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.image {
  &__center {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (min-width: 768px) {
  .about {
    &__img {
      width: 50rem;
    }
  }
}
